.w-150 {
  width: 150px;
}

$saving-sizes: (
    "with-backups": 50,
    "without-backups": 60
);

.saving {
  @each $name, $size in $saving-sizes {
    &.#{$name} {
      .saving-currency {
        font-size: #{$size / 2}px;
      }
      .saving-cost {
        font-size: #{$size}px;
      }
      .saving-unit {
        font-size: #{$size / 4}px;
      }
      .saving-currency-unit {
        font-size: #{$size / 4}px;
      }
      .saving-cost-unit {
        font-size: #{$size / 2}px;
      }
      .saving-unit-unit {
        font-size: #{$size / 4}px;
      }
    }
  }

  .saving-currency,
  .saving-currency-unit {
    font-weight: 400;
  }
  .saving-cost,
  .saving-cost-unit {
    font-weight: 300;
  }
  .saving-unit,
  .saving-unit-unit {
    font-weight: 500;
  }
}

.price {
  .price-currency {
    font-size: 15px;
    font-weight: 400;
  }
  .price-cost {
    font-size: 30px;
    font-weight: 300;

  }
  .price-unit {
    font-size: 10px;
    font-weight: 500;
  }
}