@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  $offset: $navbar-brand-height + $navbar-padding-y * 4;

  .form {
    position: sticky;
    top: $offset;
    height: calc(100vh - #{$offset});
    overflow: hidden;

    .form-container {
      overflow-y: scroll;
    }
  }
}