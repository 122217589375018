// DO NOT EDIT BELOW THAT LINE
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
// ============================

// Bootstrap theme customisations go here:
$form-validation-states: map-remove($form-validation-states, "valid");

// Load AWS font
@font-face {
  font-family: AmazonEmber;
  src: url('resources/fonts/AmazonEmber_Rg.ttf') format('truetype');
}

// Override bootstrap colors with AWS colors
$theme-colors: (
        "primary": #527FFF, // Blue
        "secondary": #6c757d,
        "success": #16BF9F, // Green
        "danger": #FF6138, // Light Amazon orange
        "warning":  #D45B07, // Dark Amazon Orange
        "light": #f8f9fa,
        "white": #FFFFFF, // White
        "dark": #232F3E, // Squid Ink
        //"danger":
);

// ============================

// DO NOT EDIT BELOW THAT LINE
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";
// ============================